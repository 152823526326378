/* Footer.css */
.footer {
  width: 100%;
  margin-top: auto;
  background-color: #3C0301;
  color: #FFF;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

/* Transparent variation */
.footer.transparent {
  background-color: transparent !important;
}

.footer-links {
  display: flex;
  gap: 20px;
}

.footer-links li {
  list-style: none;
  color: #FFF;
  cursor: pointer;
}

.footer-links li:hover {
  text-decoration: underline;
}

/* Mobile Styles for Footer */
@media (max-width: 768px) {
  .footer {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  
  .footer-links {
    margin-top: 10px;
    gap: 10px;
  }
}
