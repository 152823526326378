/* -------------------------------------------------- */
/* Base Styles */
/* -------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  color: #333;
  background-color: transparent;
}

.landing-page {
  position: relative;
}

/* -------------------------------------------------- */
/* Navbar (Desktop) */
/* -------------------------------------------------- */

.nav-logo img {
  height: 50px;
  transition: filter 0.3s ease;
}

/* Removed yellow filter hover effect from the home icon */
.nav-logo-img:hover {
  filter: none;
}

/* Container for desktop links + button */
.desktop-nav {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Links on left, button on right */
  width: 100%;
  margin-left: 30px; /* Optional spacing from the logo */
}

/* Nav links */
.nav-links {
  display: flex;
  gap: 30px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  color: #FFF;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  transition: color 0.3s;
}

/* Removed yellow hover effect for nav items */
/* .nav-item:hover {
  color: #FFD700;
} */

/* Button container on the far right */
.top-right-button {
  margin-left: auto;
}

.nav-button {
  background-color: #FFF;
  border: none;
  color: #3C0301;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-left: 10px;
}

/* Updated nav-button hover style to remove yellow effect */
.nav-button:hover {
  background-color: #FFF;
  color: #3C0301;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.nav-button:active {
  transform: scale(0.98);
  box-shadow: none;
}

/* -------------------------------------------------- */
/* Mobile Nav Toggle (hidden by default on desktop) */
/* -------------------------------------------------- */
.mobile-nav-toggle {
  display: none;
  cursor: pointer;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
}

.hamburger .bar {
  height: 3px;
  background-color: #FFF;
  border-radius: 3px;
}

/* -------------------------------------------------- */
/* Mobile Menu (shown when toggle is active) */
/* -------------------------------------------------- */
.mobile-menu {
  position: absolute;
  top: 70px; /* Adjust based on your navbar height */
  left: 0;
  right: 0;
  background-color: #3C0301;
  padding: 20px;
  z-index: 999;
}

.mobile-nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav-links .mobile-nav-item {
  padding: 10px 0;
  border-bottom: 1px solid #FFF;
  color: #FFF;
  font-size: 20px;
  cursor: pointer;
}

.mobile-nav-links .mobile-nav-item:last-child {
  border-bottom: none;
}

.mobile-nav-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

/* -------------------------------------------------- */
/* Video Background */
/* -------------------------------------------------- */
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 0; /* <-- Video behind everything else */
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -------------------------------------------------- */
/* Hero Content */
/* -------------------------------------------------- */
.hero-content {
  position: relative;
  z-index: 1; /* <-- On top of the video */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #fff;
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  color: #fff !important;
}

.hero-subtitle {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  color: white;
}

.cta-container {
  display: flex;
  gap: 20px;
}

/* CTA button styles */
.cta-button {
  padding: 16px 40px;
  background-color: #FFF;
  border: none;
  border-radius: 25px;
  color: #3C0301;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button-outline {
  background-color: transparent;
  color: #FFF;
  border: 2px solid #FFF;
  font-size: 18px;
  padding: 16px 40px;
  border-radius: 25px;
}

.cta-button-outline:hover {
  background-color: transparent; /* Keeps the background transparent */
  color: #3C0301;               /* Changes the text color on hover */  
}

.cta-button:hover {
  background-color: #FFF;
}

/* -------------------------------------------------- */
/* USP Section */
/* -------------------------------------------------- */
.usp-section {
  position: relative;
  z-index: 1; /* <-- On top of the video */
  padding: 80px 50px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.usp-item h2 {
  color: #3C0301;
  font-size: 28px;
  margin-bottom: 20px;
}

.usp-item p {
  font-size: 16px;
  color: #666;
}

/* New Core Values Styles */
.core-values-list {
  list-style-type: none;
  column-count: 2;
  column-gap: 20px; /* Adjust gap as needed */
}

.core-values-list li {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}

/* -------------------------------------------------- */
/* Footer */
/* -------------------------------------------------- */
.footer {
  position: relative;
  z-index: 1; /* <-- On top of the video */
  background-color: #3C0301;
  color: #FFF;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 20px;
}

.footer-links li {
  list-style: none;
  color: #FFF;
  cursor: pointer;
}

.footer-links li:hover {
  text-decoration: underline;
}

/* -------------------------------------------------- */
/* Mobile Styles (Below 768px) */
/* -------------------------------------------------- */
@media (max-width: 768px) {
  /* Hide the desktop nav; show mobile toggle */
  .desktop-nav {
    display: none;
  }

  /* Show the hamburger icon, on the same row as the logo */
  .mobile-nav-toggle {
    display: block;
    margin-left: auto; /* pushes it to the right */
  }

  /* Smaller hero text on mobile only */
  .hero-title {
    font-size: 28px; /* Smaller than 48px */
    color: #fff !important;
  }

  .hero-subtitle {
    font-size: 18px; /* Smaller than 20px */
    margin: 10px;
  }

  .cta-container {
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
  }

  .usp-section {
    flex-direction: column;
    padding: 40px 20px;
  }

  .usp-item {
    margin-bottom: 20px;
  }

  .footer {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .footer-links {
    margin-top: 10px;
    gap: 10px;
  }
}
