/* General container styles */
.resources-container {
    font-family: 'Inter', sans-serif;
    color: #333;
    background-color: #fff;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Page content */
.resources-content {
    margin-top: 100px;
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 10px;
    flex-grow: 1;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.resources-content h1 {
    font-size: 48px;
    color: #3C0301;
}

.resources-intro {
    font-size: 18px;
    margin-bottom: 30px;
    color: #666;
}

/* Sections */
.resources-section {
    margin-top: 50px;
}

.resources-section h2 {
    font-size: 28px;
    color: #3C0301;
    margin-bottom: 20px;
}

/* Resource Cards */
.resource-card {
    background-color: #fff;
    padding: 20px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 600px;
}

.resource-card h3 {
    font-size: 22px;
    color: #3C0301;
}

.resource-card p {
    font-size: 16px;
    color: #666;
}

.read-more {
    margin-top: 10px;
    background-color: #3C0301;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.read-more:hover {
    background-color: #7D0B02;
}

/* FAQ Section */
.faq-item {
    background-color: #fff;
    padding: 15px;
    margin: 10px auto;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    max-width: 600px;
}

.faq-item summary {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #3C0301;
}

.faq-item p {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .resources-content {
        margin-top: 80px;
        padding: 20px;
    }

    .resources-content h1 {
        font-size: 32px;
    }

    .resources-section h2 {
        font-size: 24px;
    }

    .resource-card {
        padding: 15px;
    }

    .faq-item summary {
        font-size: 16px;
    }

    .faq-item p {
        font-size: 14px;
    }
}
