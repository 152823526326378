/* App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #f9f9f9; /* Neutral background */
  color: #000;             /* Default text color */
}
