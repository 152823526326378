/* Base layout for the page */
.cta-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Content section styling */
  .cta-content {
    flex: 1; /* Expands to fill available space between Navbar and Footer */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 60px 20px;
    background-color: #fff; /* White background */
    color: #3C0301;
  }
  
  .cta-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .cta-content p {
    font-size: 20px;
    max-width: 600px;
    margin-bottom: 30px;
  }
  
  /* Newsletter form styling */
  .cta-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    gap: 15px;
  }
  
  .cta-form input[type="email"] {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
  }
  
  .cta-form input[type="email"]:focus {
    border-color: #3C0301;
    outline: none;
  }
  
  .cta-form button {
    padding: 12px 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #3C0301;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-form button:hover {
    background-color: #7D0B02;
  }

  .cta-message {
    margin-top: 20px; /* Adjust the value as needed */
    font-size: 18px; /* Optional: adjust font size */
    color: #3C0301;  /* Optional: ensure it matches your design */
  }
  