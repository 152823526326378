/* Navbar.css */

/* Navbar Container */
.navbar {
  display: flex;
  align-items: center;
  background-color: #3C0301 !important;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 20px 50px;
}

/* Transparent variation */
.navbar.transparent {
  background-color: transparent !important;
}

/* Logo */
.nav-logo img {
  height: 50px;
  transition: filter 0.3s ease;
}
.nav-logo-img:hover {
  filter: none;
}

/* Desktop Navigation */
.desktop-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 30px;
}
.nav-links {
  display: flex;
  gap: 30px;
  list-style-type: none;
}
.nav-item {
  color: #FFF;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  transition: color 0.3s;
}
.top-right-button {
  margin-left: auto;
}
.nav-button {
  background-color: #FFF;
  border: none;
  color: #3C0301;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-left: 10px;
}
.nav-button:hover {
  background-color: #FFF;
  color: #3C0301;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.nav-button:active {
  transform: scale(0.98);
  box-shadow: none;
}

/* Mobile Nav Toggle */
.mobile-nav-toggle {
  display: none;
  cursor: pointer;
}
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
}
.hamburger .bar {
  height: 3px;
  background-color: #FFF;
  border-radius: 3px;
}

/* Mobile Menu */
.mobile-menu {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  background-color: #3C0301;
  padding: 20px;
  z-index: 999;
}
.mobile-nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.mobile-nav-links .mobile-nav-item {
  padding: 10px 0;
  border-bottom: 1px solid #FFF;
  color: #FFF;
  font-size: 20px;
  cursor: pointer;
}
.mobile-nav-links .mobile-nav-item:last-child {
  border-bottom: none;
}
.mobile-nav-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

/* Responsive Mobile Styles */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
  .mobile-nav-toggle {
    display: block;
    margin-left: auto;
  }
  .navbar {
    padding: 10px 20px;
  }
  .nav-logo {
    margin-left: -10px;
  }
}
