/* Global Styles & Background */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
  font-family: 'Inter', sans-serif;
}

/* Container */
.contact-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: transparent;
}

/* Main Content */
.contact-content {
  margin: 120px auto; /* increased margin-top for more spacing from the navbar */
  padding: 40px 50px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Headings & Text */
.contact-content h1 {
  color: #3C0301;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.contact-description {
  color: #666;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

/* Support Phone Section */
.support-phone {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #3C0301;
  line-height: 1.5; /* ensures proper spacing between lines */
}

.support-phone a {
  text-decoration: none;
  font-weight: 600;
  color: #3C0301;
  transition: color 0.3s ease;
}

.support-phone a:hover {
  color: #7D0B02;
}

/* Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form label {
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.25rem;
  color: #3C0301;
}

.contact-form input,
.contact-form textarea {
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #3C0301;
}

/* Submit Button */
.contact-submit {
  padding: 0.75rem;
  background-color: #3C0301;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact-submit:hover {
  background-color: #7D0B02;
  transform: translateY(-2px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-content {
    margin: 120px auto; /* Updated margin for mobile */
    padding: 20px;
  }

  .contact-content h1 {
    font-size: 2rem;
  }

  .contact-description,
  .support-phone,
  .contact-form label,
  .contact-form input,
  .contact-form textarea,
  .contact-submit {
    font-size: 0.9rem;
  }
}
