/* General container styles */
.terms-container {
  font-family: 'Inter', sans-serif;
  color: #333;
  background-color: #fff;
  padding: 0;  /* Removed extra padding */
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Terms of service content */
.terms-content {
  margin-top: 100px;
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 10px;
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.terms-content h1 {
  color: #3C0301;
  text-align: center;
  font-size: 48px;
}

.last-updated {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

.terms-section {
  margin-top: 40px;
}

.terms-section h2 {
  font-size: 28px;
  color: #3C0301;
}

.terms-section p {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
}

/* Mobile Styles (Below 768px) */
@media (max-width: 768px) {
  .terms-content {
      margin-top: 80px;
      padding: 20px;
  }

  .terms-content h1 {
      font-size: 32px;
  }

  .last-updated {
      font-size: 16px;
  }

  .terms-section h2 {
      font-size: 24px;
  }

  .terms-section p {
      font-size: 16px;
  }
}
