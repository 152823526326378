/* PricingPage.css */

/* Container styling for PricingPage */
.pricing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff; /* White background */
  color: #333;           /* Dark text */
}

/* Pricing Section */
.pricing-section {
  padding: 80px 50px;
  margin-top: 100px;
  background-color: #f8f8f8;
  text-align: center;
  flex-grow: 1;
}
.hero-title {
  font-size: 48px;
  color: #3C0301;
  text-shadow: none;
}
.pricing-plans {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 40px;
}

/* Pricing Cards */
.pricing-card {
  background: linear-gradient(135deg, #3C0301 0%, #7D0B02 100%);
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  width: 30%;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.pricing-card:hover {
  transform: scale(1.05);
}
.pricing-card h2 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 15px;
}
.pricing-card p {
  color: #fff;
  font-size: 18px;
  line-height: 1.6;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .pricing-section {
    padding: 40px 20px;
    margin-top: 80px;
  }
  .hero-title {
    font-size: 32px;
  }
  .pricing-plans {
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  .pricing-card {
    width: 100%;
    margin-bottom: 20px;
  }
}
