/* GetAppPage.css */

/* Page Container */
.getapp-page {
  min-height: 100vh;
  position: relative;
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  color: #fff;
}

/* Main Content */
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  text-align: center;
  min-height: 100vh;
}

/* Titles & Release Info */
.page-title {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 50px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}
.release-info {
  font-size: 24px;
  margin-bottom: 40px;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4);
}

/* Countdown Timer */
.countdown-timer {
  font-size: 36px;
  margin-bottom: 40px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.countdown-timer span {
  background: rgba(255, 255, 255, 0.2);
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Download Links */
.download-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.download-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #3C0301;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.download-button:hover {
  transform: translateY(-3px);
  background-color: #f0f0f0;
}

/* Store Icon Styling */
.store-icon {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .page-title {
    font-size: 40px;
  }
  .release-info {
    font-size: 18px;
  }
  .countdown-timer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    font-size: 24px;
  }
}
