/* ProductsPage.css */

/* Container styling for ProductsPage */
.products-page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff; /* White background for the entire page */
  color: #333;           /* Dark text for body copy */
  font-family: 'Inter', sans-serif;
  padding-bottom: 60px;
}

/* Page Title */
.page-title {
  text-align: center;
  margin: 100px auto 50px;
  max-width: 800px;
  padding: 20px;
}

.page-title h1 {
  font-size: 3rem;
  font-weight: 800;
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  margin: 0;
  text-shadow: none; /* Remove any text shadow */
}

.page-title p {
  font-size: 1.5rem;
  margin-top: 10px;
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  margin: 0;
  text-shadow: none; /* Remove any text shadow */
}

/* Pricing Section using grid layout */
.pricing-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 0 40px 60px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Pricing Card */
.pricing-plan {
  background: linear-gradient(135deg, #3C0301, #7D0B02);
  color: #fff;
  padding: 40px;
  border-radius: 15px;
  transition: transform 0.3s ease, background 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}

.pricing-plan:hover {
  transform: translateY(-5px);
}

.pricing-plan h2 {
  font-size: 2rem; /* Increased from 1.75rem to 2rem */
  margin-bottom: 20px;
  font-weight: 700;
  text-shadow: none; /* Remove any text shadow */
}

.pricing-plan ul {
  list-style: none;
  font-size: 1.25rem; /* Increased from 1rem to 1.25rem */
  line-height: 1.6;
  padding: 0;
  margin: 0 0 20px;
  flex-grow: 1;
}

.pricing-plan ul li {
  margin-bottom: 10px;
  padding-left: 1.2em;
  position: relative;
}

.pricing-plan ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #fff;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .page-title {
    margin-top: 80px;
    padding: 0 20px;
  }
  .page-title h1 {
    font-size: 2.5rem;
  }
  .page-title p {
    font-size: 1.25rem;
  }
  .pricing-section {
    padding: 0 20px 40px;
  }
}
